<template>
  <div id="inviter">
    <div class="admin_main_block">
      <div class="user_temp_title">推广赚钱</div>
      <div class="admin_table_main">
        <div class="inviter_main" style="margin: 60px 10px">
          <div class="description">每个用户都有自己的独立邀请码生成的邀请链接，只要别人点击你的推广链接注册下单系统自动奖励你，奖励金额实时进入你的账户余额</div>
          <div class="url">
            我发现一个好用礼品代发网站，你也看看，注册网址是 {{ inviteUrl }}
          </div>
          <div class="btn">
            <el-button size="small" type="primary" @click="copy"
              >复制网址，邀请好友</el-button
            >


          </div>
        </div>
      </div>

      <div class="" style="font-weight: bold;margin:25px 35px 8px 18px">奖励规则</div>
      <div class="description" style="margin-left:18px">当前标准奖励方案：0.09~0.11 元/单</div>
      <div class="user_temp_title" style="height: 0px"></div>

      <div class="user_temp_title" style="margin-top: 40px">奖励明细</div>
      <div class="admin_table_main" style="margin-top: 10px">
        <el-table size="small" :data="rewardList" v-loading="loadingReward" >
          <el-table-column label="创建时间" prop="createTime"  width="140">
            <template slot-scope="scope">
              {{ (scope.row.createTime / 1000) | date("Y-m-d H:i:s") }}
            </template>
          </el-table-column>
          <el-table-column label="类型" width="60">
            <template slot-scope="scope">
              <span>{{ scope.row.type == 1 ? "收入" : "支出" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="资金变动" prop="amount" width="90">
            <template slot-scope="scope">
              <span>{{
                  scope.row.type == 1
                      ? "+" + scope.row.amount
                      : "-" + scope.row.amount
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="dealDesc">
            <template slot-scope="scope">
              <span
              >{{ scope.row.remark ? "【" + scope.row.remark + "】" : ""
                }}{{ scope.row.dealDesc }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 30px;text-align:right;">
          <el-pagination
              v-if="rewardTotal > 0"
              background
              @current-change="handleCurrentChange"
              :current-page="searchRewardData.pageNo"
              :page-size="searchRewardData.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="rewardTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div class="user_temp_title" style="margin-top: 40px">我的推荐商家</div>
      <div class="admin_table_main" style="margin-top: 40px">
        <el-table size="small" :data="list" v-loading="loading">
          <el-table-column label="手机号码" prop="phone"></el-table-column>
          <el-table-column label="注册时间">
            <template slot-scope="scope">
              <span>{{ $time.getDate(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 30px;margin;text-align:right;">
          <el-pagination
            v-if="total > 0"
            background
            @current-change="handleCurrentChange"
            :current-page="searchData.pageNo"
            :page-size="searchData.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      inviteUrl: "",
      vipList: [],
      loading: false,
      loadingReward: false,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        id: localStorage.getItem("ids"),
      },
      searchRewardData: {
        pageNo: 1,
        pageSize: 10,
        dealType: "3",
        id: localStorage.getItem("ids"),
      },
      list: [],
      rewardList:[],
      total: 0,
      rewardTotal:0
    };
  },
  created() {
    this.getInviteUrl();
    this.getVipList();
    this.getList();
    this.getRewardList()
  },
  mounted() {},
  watch: {},
  methods: {
    getInviteUrl() {
      this.$request.post({
        url: "/ReportApi/business/invitor/getInvitorCode",
        success: (res) => {
          this.inviteUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            "/?inviteCode=" +
            res;
        },
        finally: () => {},
      });
    },
    getRewardList() {
      this.loadingReward= true;
      this.$request.post({
        url: "/ReportApi/business/capitalFlow/getCapitalFlow",
        params: this.searchRewardData,
        success: (res) => {
          this.rewardList = res.data;
          this.rewardTotal = res.total;
        },
        finally: () => {
          this.loadingReward = false;
        },
      });
    },
    copy() {
      var input = document.createElement("input");
      input.value = this.inviteUrl;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length),
        document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success("复制成功!");
    },
    getVipList() {
      this.$request.post({
        url: "/ReportApi/business/invitor/getVipList",
        success: (res) => {
          this.vipList = res;
        },
        finally: () => {},
      });
    },
    getList() {
      this.loading = true;
      this.$request.post({
        url: "/ReportApi/business/invitor/getInvitorUsers",
        params: this.searchData,
        success: (res) => {
          this.list = res.data;
          this.total = res.total;
        },
        finally: () => {
          this.loading = false;
        },
      });
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
#inviter {
  .admin_main_block {
    background: #fff;
    width: 100%;
    min-height: 855px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .description{
      font-size: 14px;
      color: #999;
    }
    .admin_table_main {
      .inviter_main {
        margin: 40px 80px 0 80px;
        .title {
          font-size: 15px;
          line-height: 36px;
        }
        .url {
          border: 1px solid #efefef;
          border-radius: 6px;
          line-height: 40px;
          font-size: 14px;
          padding-left: 10px;
          margin-bottom: 20px;
        }
        .success {
          font-size: 18px;
          line-height: 90px;
        }
        .tip {
          color: #ea242f;
          background: #ffefe2;
          padding: 18px 22px;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
